import { Presenter } from '~/framework/Presenter';
import { User } from '~/state/mainAppState';

export interface PresentableCompayStyle {
  name: string;
  logo?: string;
  cover?: string;
  primaryColor?: string;
  hoverColor?: string;
  activeColor?: string;
  gradientStartColor?: string;
  gradientEndColor?: string;
  email?: string;
  phoneNumber?: string;
  facebook?: string;
  instagram?: string;
  linkedIn?: string;
  twitter?: string;
  tikTok?: string;
  youtube?: string;
  metadataTitle?: string;
  metadataDescription?: string;
  metadataThemeColor?: string;
  metadataTitleTemplate?: string;
}

export class CompanyStylePresenter extends Presenter<PresentableCompayStyle> {
  protected createModel = (state: User): PresentableCompayStyle => {
    return {
      name: state.companyInfo?.name || '',
      logo: state.companyInfo?.logo ? '/api' + state.companyInfo?.logo : '',
      cover: state.companyInfo?.coverUrl ? '/api' + state.companyInfo?.coverUrl : '',
      primaryColor: state.companyInfo?.style.primaryColor,
      hoverColor: state.companyInfo?.style.hoverColor,
      activeColor: state.companyInfo?.style.activeColor,
      gradientStartColor: state.companyInfo?.style.gradientStartColor,
      gradientEndColor: state.companyInfo?.style.gradientEndColor,
      email: state.companyInfo?.email,
      phoneNumber: state.companyInfo?.phoneNumber,
      facebook: state.companyInfo?.facebook,
      instagram: state.companyInfo?.instagram,
      linkedIn: state.companyInfo?.linkedIn,
      twitter: state.companyInfo?.twitter,
      tikTok: state.companyInfo?.tikTok,
      youtube: state.companyInfo?.youtube,
      metadataTitle: state.companyInfo?.metadataTitle,
      metadataDescription: state.companyInfo?.metadataDescription,
      metadataThemeColor: state.companyInfo?.metadataThemeColor,
      metadataTitleTemplate: state.companyInfo?.metadataTitleTemplate,
    };
  };
}
